<template>
  <div>
    <h2>Compose SMS</h2>
    <div class="row" v-if="! loading">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>To</label>
                <input
                  type="text"
                  placeholder="Phone Number, comma separated"
                  class="form-control"
                  v-model="sms.to"
                  required
                  v-if="sms.type == 'individual'"
                />
                <input type="text" disabled :value="receipient_description" v-if="sms.type != 'individual'" class="form-control">
              </div>
              <div class="form-group">
                <label>Content</label>
                <textarea v-model="sms.content" class="form-control" placeholder="Content"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-primary">
                  <i class="fa fa-send me-4"></i>Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Recipients</h6>
            <ul class="px-0">
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="individual"
                  id="r-individual"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-individual" id="l-individual"
                  >Individual(s)</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="all"
                  id="r-all"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-all" id="l-all"
                  >All Users</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="admin"
                  id="r-admin"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-admin" id="l-admin"
                  >Admins And Modertors</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="clients"
                  id="r-clients"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-clients" id="l-clients"
                  >All Clients</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="pending"
                  id="r-pending"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-pending" id="l-pending"
                  >Clients Wih Pending Ads</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="active"
                  id="r-active"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-active" id="l-active"
                  >Clients with Active Ads</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="new"
                  id="r-new"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-new"  id="l-new"
                  >Clients With 0 Ads</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="unpubished"
                  id="r-unpubished"
                  class="form-check-input"
                  v-model="sms.type"
                />
                <label class="form-check-label" for="r-unpubished" id="l-unpubished"
                  >Clients With Unpublished Ads</label
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" message="Sending..." />
  </div>
</template>

<script>
export default {
  data() {
    return {
      sms: { type: 'individual' },
      loading: false
    };
  },

  methods: {
    submit() {
      this.loading = true

      let data = {
        method: 'sms',
        type: this.sms.type,
        recipients: this.sms.to,
        content: {
          subject: this.sms.subject,
          content: this.sms.content
        }
      }

      this.$axios.post('/api/v1/admin/announcements', data).then(response => {
        this.loading = false
        this.$router.push({ name: 'admin.announcements.show', params: { id: response.data.id }})
      })
    }
  },

  computed: {
    receipient_description() {
      return document.getElementById(`l-${this.sms.type}`).innerHTML
    }
  }
};
</script>

<style lang="scss" scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  padding-left: 0;
}

[type=radio]:checked + label:after, [type=radio]:not(:checked) + label:after {
  display: none;
}
</style>
