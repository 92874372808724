<template>
  <div>
    <h2>Compose Email</h2>
    <div class="row" v-if="!loading">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>To</label>
                <input
                  type="text"
                  placeholder="Email, comma separated"
                  class="form-control email-suggestions-input"
                  v-model="email.to"
                  required
                  v-if="email.type == 'individual'"
                />
                <ul v-if="email.type == 'individual'" class="email-suggestions">
                  <li v-for="(eml, i) in show_emails" :key="`email-${i}`">
                    <a href="#" @click.prevent="email.to = eml">{{ eml }}</a>
                  </li>
                </ul>
                <input
                  type="text"
                  disabled
                  :value="receipient_description"
                  v-if="email.type != 'individual'"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  placeholder="Subject"
                  class="form-control"
                  v-model="email.subject"
                  required
                />
              </div>
              <div class="form-group">
                <label>Content</label>
                <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
                  v-model="email.content"
                  :init="{
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help',
                  }"
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary">
                  <i class="fa fa-send me-4"></i>Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Recipients</h6>
            <ul class="px-0">
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="individual"
                  id="r-individual"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label
                  class="form-check-label"
                  for="r-individual"
                  id="l-individual"
                  >Individual(s)</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="all"
                  id="r-all"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label class="form-check-label" for="r-all" id="l-all"
                  >All Users</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="admin"
                  id="r-admin"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label class="form-check-label" for="r-admin" id="l-admin"
                  >Admins And Modertors</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="clients"
                  id="r-clients"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label class="form-check-label" for="r-clients" id="l-clients"
                  >All Clients</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="pending"
                  id="r-pending"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label class="form-check-label" for="r-pending" id="l-pending"
                  >Clients Wih Pending Ads</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="active"
                  id="r-active"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label class="form-check-label" for="r-active" id="l-active"
                  >Clients with Active Ads</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="new"
                  id="r-new"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label class="form-check-label" for="r-new" id="l-new"
                  >Clients With 0 Ads</label
                >
              </li>
              <li class="form-check">
                <input
                  type="radio"
                  name="recipients"
                  value="unpubished"
                  id="r-unpubished"
                  class="form-check-input"
                  v-model="email.type"
                />
                <label
                  class="form-check-label"
                  for="r-unpubished"
                  id="l-unpubished"
                  >Clients With Unpublished Ads</label
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" message="Sending..." />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      email: {
        type: "individual",
        content: `<br><br><br><br><h4>Contact Us</h4><p>Phone Number: ${this.$store.getters.getContact.phone_number}</p><p>Email: ${this.$store.getters.getContact.email}</p><p>Address: ${this.$store.getters.getContact.location}</p><p>Website: <a href="https://realhub.co.ke">realhub.co.ke</a></p>`,
        to: ''
      },
      loading: false,
      emails: [],
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/admin/announcements/create").then((response) => {
        this.emails = response.data.emails;
      });
    },

    submit() {
      this.loading = true;

      let data = {
        method: "email",
        type: this.email.type,
        recipients: this.email.to,
        content: {
          subject: this.email.subject,
          content: this.email.content,
        },
      };

      this.$axios.post("/api/v1/admin/announcements", data).then((response) => {
        this.loading = false;
        this.$router.push({
          name: "admin.announcements.show",
          params: { id: response.data.id },
        });
      });
    },
  },

  computed: {
    receipient_description() {
      return document.getElementById(`l-${this.email.type}`).innerHTML;
    },

    show_emails() {
      if(this.email.to.length > 0) {
        return this.emails.filter(email => {
          return email.indexOf(this.email.to) >= 0
        })
      }

      return this.emails
    }
  },
};
</script>

<style lang="scss" scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: relative;
  left: 0;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  padding-left: 0;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  display: none;
}
</style>
